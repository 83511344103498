import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ICheckin,
  IComment,
  IGoal,
  IGoalAndActionPlan,
  IGoalDocument,
  IMember,
  ITaskActionPlan,
  IThemeGoal
} from '../interfaces/action-plans.interface';
import { IGenericResponse } from '../interfaces/generic.interface';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {
  apiUrl = `${environment.apiUrl}/goalsAndActionPlan`;

  availableMembers = signal<IMember[]>([]);
  configGoal = signal<{
    themeId: string;
    themeName: string;
    goalsAndActionPlanId: string;
  } | null>(null);

  changeMembersEvent = new Subject<void>();

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {}

  getThemesGoals(): Observable<IGenericResponse<IGoalAndActionPlan>> {
    return this.http.get<IGenericResponse<IGoalAndActionPlan>>(
      `${this.apiUrl}/${this.customerService.customerActive()?.id}`
    );
  }

  createThemesGoals(themeIds: string[]): Observable<void> {
    let data = {
      companyId: this.customerService.customerActive()?.id,
      themeIds
    };
    return this.http.post<void>(`${this.apiUrl}`, data);
  }

  updateActiveThemesGoals(data: IThemeGoal): Observable<void> {
    const goalsAndActionPlan = {
      themeId: data.themeId,
      goalsAndActionPlanId: data.goalsAndActionPlanId,
      activate: data.active
    };

    return this.http.patch<void>(`${this.apiUrl}/activate`, goalsAndActionPlan);
  }

  getGoal(goalId: string): Observable<IGenericResponse<IGoal>> {
    return this.http.get<IGenericResponse<IGoal>>(
      `${this.apiUrl}/goal/${goalId}`
    );
  }

  createGoal(data: IGoal): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/goal`, data);
  }

  updateGoal(data: IGoal): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/goal`, data);
  }

  deleteGoal(goalId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/goal/${goalId}`);
  }

  getAvailableMembers(): Observable<IGenericResponse<IMember[]>> {
    return this.http.get<IGenericResponse<IMember[]>>(
      `${this.apiUrl}/${
        this.customerService.customerActive()?.id
      }/availableMembers`
    );
  }

  insertMember(goalId: string, member: IMember): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/goal/${goalId}/member`, member);
  }

  removeMember(goalId: string, member: IMember): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/goal/${goalId}/member/${member.id}`
    );
  }

  createActionPlan(
    goalId: string,
    actionPlan: ITaskActionPlan
  ): Observable<ITaskActionPlan[]> {
    return this.http
      .post<IGenericResponse<IGoal>>(
        `${this.apiUrl}/goal/${goalId}/actionPlan`,
        actionPlan
      )
      .pipe(
        map((response) => {
          return response.data.actionPlans;
        })
      );
  }

  updateActionPlan(
    goalId: string,
    actionPlan: ITaskActionPlan
  ): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/goal/${goalId}/actionPlan`,
      actionPlan
    );
  }

  deleteActionPlan(goalId: string, actionPlanId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/goal/${goalId}/actionPlan/${actionPlanId}`
    );
  }

  updateMetricValue(
    goalId: string,
    metricId: string,
    value: string
  ): Observable<ICheckin> {
    return this.http.post<ICheckin>(
      `${this.apiUrl}/goal/${goalId}/metric/${metricId}/entry`,
      { value, metricId }
    );
  }

  uploadGoalFile(goalId: string, file: File): Observable<IGoalDocument> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<IGenericResponse<IGoalDocument>>(
        `${this.apiUrl}/goal/${goalId}/attachment`,
        formData
      )
      .pipe(map((response) => response.data));
  }

  downloadGoalFile(goalId: string, fileId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/goal/${goalId}/attachment/${fileId}`, {
      responseType: 'blob'
    });
  }

  deleteGoalFile(goalId: string, fileId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/goal/${goalId}/attachment/${fileId}`
    );
  }

  createComment(goalId: string, comment: IComment): Observable<IComment[]> {
    return this.http
      .post<IGenericResponse<IGoal>>(
        `${this.apiUrl}/goal/${goalId}/activity`,
        comment
      )
      .pipe(
        map((response) => {
          return response.data.activities;
        })
      );
  }

  updateComment(goalId: string, comment: IComment): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/goal/${goalId}/activity`,
      comment
    );
  }

  deleteComment(goalId: string, commentId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/goal/${goalId}/activity/${commentId}`
    );
  }
}
